<template>
  <div class="mb-4 main_content">
    <table density="comfortable" :key="`amountTable${index}`">
      <thead>
        <tr>
          <th class="text-center">
            最小量
          </th>
          <th class="text-center">
            
          </th>
          <th class="text-center">
            最大量
          </th>
          <th class="text-center">
            
          </th>
          <th class="text-center">
            優惠單價
          </th>
          <th class="text-center">
            
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <v-text-field
              outlined
              dense
              type="number"
              v-model="data.min"
              hide-details
            >
            </v-text-field>
          </td>
          <td>~</td>
          <td>
            <v-text-field
              outlined
              dense
              type="number"
              v-model="data.max"
              hide-details
            >
            </v-text-field>
          </td>
          <td>
            <template v-if="unit">({{ unit }})</template>
          </td>
          <td>
            <v-text-field
              outlined
              dense
              type="number"
              v-model="data.price"
              hide-details
            ></v-text-field>
          </td>
          <td>元</td>
        </tr>
      </tbody>
    </table>
    <v-btn class="delete_icon" @click="removeItem">
      <i aria-hidden="true" class="v-icon notranslate ri ri-delete-bin-2-fill theme--light"></i>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    unit: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
    },
  },
  data: () => ({
    data: null,
  }),
  computed: {
    discountConfigs() {
      return this.formData.discount_config;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        const data = this.value[this.index];
        this.data = this.$eagleLodash.cloneDeep(data);
      },
    },
    data: {
      deep: true,
      handler() {
        const data = this.value[this.index];
        if (this.$eagleLodash.isEqual(this.data, data)) return;

        const value = this.$eagleLodash.cloneDeep(this.value);
        value[this.index] = this.$eagleLodash.cloneDeep(this.data);
        this.$emit("input", value);
      },
    },
  },
  methods: {
    removeItem() {
      this.$emit('deleteItem', this.index)
    },
  },
};
</script>
<style lang="scss" scoped>
.main_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #F1F1F1;
  padding: 10px;
  th {
    font-size: 13px;
    font-weight: normal;
    padding-bottom: 3px;
    color: #aaa;
  }
  td {
    font-size: 13px;
    padding: 0 3px;
    color: #aaa;
  }
  .delete_icon {
    min-width: 48px;
    min-height: 36px;
    border-radius: 10px;
    background: #f6f8fa;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none !important;
    .theme--light.v-icon {
      font-size: 25px;
      color: #009775;
    }
  }
}
</style>